<div class="title">
  <h1>Video Intelligence</h1>
  <button (click)="navigateToAddVideo()">Add New Video</button>
</div>
<div class="container-fluid">
  <div class="card">
    <p-table
      [value]="searches$"
      [rows]="paginationService.searchListPaginationState.rows"
      [first]="paginationService.searchListPaginationState.first"
      [rowHover]="true"
      [showCurrentPageReport]="true"
      [paginator]="true"
      dataKey="externalId"
      #dt
    >
      <ng-template pTemplate="header" class="header">
        <tr class="header-row">
          <td colspan="6">
            <div class="header-content">
              <th style="width: 20%; margin-left: 15px">Title</th>
              <th style="width: 15%">Video Type</th>
              <th style="width: 20%">Length</th>
              <th style="width: 35%">Link</th>
              <th style="width: 10%">Status</th>
              <th style="min-width: 89px">Actions</th>
            </div>
          </td>
        </tr>
      </ng-template>

      <ng-template pTemplate="body" let-search>
        <tr>
          <td style="width: 19.85%">
            <div class="wrapper" style="padding-left: 15px">
              {{ search.parameters.title }}
            </div>
          </td>
          <td style="width: 13.9%">
            <div class="wrapper">
              <span class="tag">{{
                search.parameters.is_stream ? "Live stream" : "Video"
              }}</span>
            </div>
          </td>
          <td style="width: 18.5%">
            <div class="wrapper">
              {{
                search.parameters.is_stream
                  ? ""
                  : formatDuration(search.parameters?.duration)
              }}
            </div>
          </td>
          <td style="width: 32.36%">
            <div class="wrapper">{{ search.parameters.video_url }}</div>
          </td>
          <td
            style="width: 9.25%"
            *ngIf="
              search.searchStatus.toLowerCase() == 'in_progress' ||
              search.searchStatus.toLowerCase() == 'started'
            "
          >
            <div class="wrapper">
              <img
                src="../../../assets/images/spinner.svg"
                alt="spinner-icon"
                class="custom-image"
              />
            </div>
          </td>
          <td
            style="width: 9.25%"
            *ngIf="search.searchStatus.toLowerCase() == 'completed'"
          >
            <div class="wrapper">
              <img
                src="../../../assets/images/completedIcon.svg"
                alt="completed-icon"
              />
            </div>
          </td>
          <td>
            <div class="wrapper">
              <div class="icons">
                <img
                  src="../../../assets/images/play.svg"
                  alt="play-icon"
                  [class.disabled]="
                    search.searchStatus.toLowerCase() !== 'completed' &&
                    !search.parameters.is_stream
                  "
                  (click)="
                    search.searchStatus.toLowerCase() === 'completed' ||
                    (search.searchStatus.toLowerCase() === 'in_progress' &&
                      search.parameters.is_stream)
                      ? seeVideo(
                          search.jobId,
                          search.parameters.is_stream,
                          search.createdAt
                        )
                      : null
                  "
                />
                <img
                  src="../../../assets/images/editIcon.svg"
                  alt="edit-icon"
                />

                <img
                  src="../../../assets/images/deleteIcon.svg"
                  alt="delete-icon"
                  (click)="deleteSearch(search)"
                />
              </div>
            </div>
          </td>
        </tr>
      </ng-template>
    </p-table>
  </div>
</div>

<app-modal
  #confirmationModal
  [isVisible]="confirmationModalVisible"
></app-modal>