<div class="layout-topbar">
  <div class="topbar-line">
    <div class="topbar-left">
      <!-- <img
        id="logo-horizontal"
        src="assets/layout/images/logo_white.svg"
        alt="diamond-layout"
        class="horizontal-logo"
        tabindex="0"
      /> -->

      <img
        id="logo-mobile"
        alt="diamond-layout"
        class="mobile-logo"
        src="../../../../assets/layout/images/dummyLogo.svg"
      />
    </div>
    <app-menu> </app-menu>

    <!-- <div class="layout-mask modal-in"></div> -->
    <div class="topbar-wrapper">
      <app-breadcrumb></app-breadcrumb>
      <div class="topbar-right flex">
        <ul class="topbar-menu">
          <li class="notifications-item">
            <a href="#" tabindex="0"
              ><i class="pi pi-bell"></i>
              <!--                    <span class="topbar-badge">5</span>-->
            </a>
          </li>
          <li class="profile-item" *ngIf="currentUser$ | async as currentUser">
            <p-menu
              #menu
              [model]="items"
              [popup]="true"
              appendTo="body"
            ></p-menu>
            <a (click)="menu.toggle($event)">
              <span class="profile-name">{{ currentUser?.userName }}</span>
              <img src="assets/layout/images/user.svg" class="profile-image" />

              <p-toast></p-toast>
            </a>

            <!--                <ul class="submenu" *ngIf="true">-->

            <!--&lt;!&ndash;                    <li (click)="logout()">Logout</li>&ndash;&gt;-->
            <!--                    &lt;!&ndash; Add any other submenu items as needed &ndash;&gt;-->
            <!--                </ul>-->
          </li>
        </ul>
      </div>
    </div>
  </div>
</div>
