<div class="video-layout">
  <h1>Add New Video</h1>

  <div class="circle-container">
    <div class="step-container">
      <div
        class="circle"
        [ngClass]="{
          active: currentStep === 1,
          filled:
            currentStep === 2 &&
            (videoSelected || streamSelected)
        }"
        (click)="goToStep(1)"
        [class.disabled]="uploaded"
      >
        <img
          *ngIf="
            currentStep === 2 &&
            (videoSelected || streamSelected)
          "
          src="../../../assets/images/correctSign.svg"
          alt="Filled Icon"
        />
        <span *ngIf="currentStep === 1">1</span>
      </div>
      <div
        class="step-text"
        [ngClass]="{
          active: currentStep === 1,
          'text-disabled': currentStep !== 1
        }"
      >
        Select Video Type
      </div>
    </div>

    <div
      class="line"
      [ngClass]="{
        'active-line': videoSelected || streamSelected
      }"
    ></div>

    <div class="step-container">
      <div
        class="circle"
        [ngClass]="{
          active: currentStep === 2,
          filled: currentStep === 2 && uploaded
        }"
        (click)="goToStep(2)"
        [class.disabled]="
          !videoSelected && !streamSelected
        "
      >
        <img
          *ngIf="uploaded"
          src="../../../assets/images/correctSign.svg"
          alt="Filled Icon"
        />
        <span *ngIf="currentStep === 2 && !uploaded">2</span>
      </div>
      <div
        class="step-text"
        [ngClass]="{
          active: currentStep === 2,
          'text-disabled': currentStep !== 2,
       
        }"
      >
        Enter Link
      </div>
    </div>
  </div>

  <div *ngIf="!uploaded">
    <div
      *ngIf="
        currentStep === 1 &&
        !videoSelected &&
        !streamSelected
      "
      class="video-content"
    >
      <button (click)="selectVideo()" class="video-button">
        <img
          src="../../../assets/images/videoCameraIcon.svg"
          alt="Video Camera image"
        />
        Video
      </button>
      <button (click)="selectStream()" class="video-button">
        <img
          src="../../../assets/images/signalStreamIcon.svg"
          alt="Signal Stream image"
        />Stream
      </button>
    </div>
  </div>
  <div
    *ngIf="
      currentStep === 2 &&
      (videoSelected || streamSelected)
    "
    class="input-container"
  >
    <label>Enter your link</label>
    <input
      type="text"
      [(ngModel)]="videoUrl"
      placeholder="Paste your link here..."
    />
    <div *ngIf="!uploading && !error && !uploaded">
      <button (click)="uploadVideo()">Upload</button>
    </div>
    <div *ngIf="uploading">
      <img
        src="../../../assets/images/spinner.svg"
        alt="Spinner image"
        class="spinner-image"
      />
      <p>Uploading...</p>
    </div>
    <div *ngIf="error" class="error">
      <p>Error occurred: {{ errorMessage }}</p>
      <button (click)="retryUpload()">Retry</button>
    </div>
    <div *ngIf="uploaded && jobId" class="success">
      <div>
        <img
          src="../../../assets/images/successIcon.svg"
          alt="Success image"
          class="success-image"
        />
        <p>Successfully uploaded and added to the list!</p>
      </div>
      <button (click)="seeVideo()">See the video</button>
    </div>
  </div>
</div>
