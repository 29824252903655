import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.scss'],
})
export class ModalComponent {
  @Input() isVisible: boolean = false;
  @Input() header: string = 'Confirmation';
  @Input() message: string = '';
  @Input() acceptText: string = 'Yes';
  @Input() cancelText: string = 'No';

  @Output() accept: EventEmitter<void> = new EventEmitter<void>();
  @Output() cancel: EventEmitter<void> = new EventEmitter<void>();

  constructor() {}

  onAccept() {
    this.accept.emit();
  }

  onCancel() {
    this.cancel.emit();
  }
}
