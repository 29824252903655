import { Component, OnInit } from "@angular/core";
import { Router, NavigationEnd } from "@angular/router";
import { MenuItem } from "primeng/api";
import { filter } from "rxjs/operators";
import { BreadcrumbService } from "src/app/helpers/breadcrumb.service";

@Component({
  selector: "app-breadcrumb",
  templateUrl: "./breadcrumb.component.html",
  styleUrls: ["./breadcrumb.component.scss"],
})
export class BreadcrumbComponent implements OnInit {
  items: MenuItem[] = [];
  title: string = "Video";

  constructor(
    private router: Router,
    private breadcrumbService: BreadcrumbService
  ) {}

  ngOnInit(): void {
    this.updateBreadcrumbs(this.title);
    this.router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe(() => {
        this.updateBreadcrumbs(this.title);
      });

    this.breadcrumbService.getVideoTitle().subscribe((title: string) => {
      this.title = title;
      this.updateBreadcrumbs(this.title);
    });
  }

  updateBreadcrumbs(videoTitle: string): void {
    const url = this.router.url;

    this.items = [];
    if (url.includes("/admin/dashboard")) {
      this.items.push({
        label: "Dashboard",
        routerLink: "/admin/dashboard",
      });
    } else if (url.includes("admin/video-intelligence")) {
      this.items.push({
        label: "Video Intelligence",
        routerLink: "/admin/video-intelligence",
      });
    } else if (url.includes("admin/add-video")) {
      this.items.push({
        label: "Video Intelligence",
        routerLink: "admin/video-intelligence",
      });
      this.items.push({
        label: "Add New Video",
        routerLink: "admin/add-video",
      });
    } else if (url.includes("admin/video")) {
      this.items.push({
        label: "Video Intelligence",
        routerLink: "admin/video-intelligence",
      });
      this.items.push({
        label: "Add New Video",
        routerLink: "admin/add-video",
      });
      this.items.push({
        label: videoTitle || "Video",
      });
    }
  }
}
