/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export type CreateDownloadYoutubeRequest = {
    videoUrl: string;
    isStream?: boolean;
    streamDuration?: number;
    partDuration?: number;
    tasks?: Array<'linkedin_profile_parse' | 'linkedin_company_parse' | 'linkedin_post_parse' | 'link' | 'post_links' | 'instagram_photo' | 'instagram_comments' | 'youtube_download' | 'youtube_metadata' | 'speech2text' | 'summarize' | 'translate' | 'pdf_content' | 'google' | 'webpage_fetch' | 'ctp_linkedin_user_access_parse'>;
    resultType?: CreateDownloadYoutubeRequest.resultType;
};
export namespace CreateDownloadYoutubeRequest {
    export enum resultType {
        JSON = 'json',
        HTML = 'html',
        FILE = 'file',
    }
}

