/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import type { Observable } from 'rxjs';
import type { AmsResponseBoolean } from '../models/AmsResponseBoolean';
import type { AmsResponseListString } from '../models/AmsResponseListString';
import type { AmsResponseRolePrivilegeDto } from '../models/AmsResponseRolePrivilegeDto';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
@Injectable({
    providedIn: 'root',
})
export class RoleControllerService {
    constructor(public readonly http: HttpClient) {}
    /**
     * @param name
     * @returns AmsResponseRolePrivilegeDto OK
     * @throws ApiError
     */
    public getRole(
        name: string,
    ): Observable<AmsResponseRolePrivilegeDto> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/api/v1/manage/roles/{name}',
            path: {
                'name': name,
            },
        });
    }
    /**
     * @param name
     * @param requestBody
     * @returns AmsResponseBoolean OK
     * @throws ApiError
     */
    public update(
        name: string,
        requestBody: string,
    ): Observable<AmsResponseBoolean> {
        return __request(OpenAPI, this.http, {
            method: 'PUT',
            url: '/api/v1/manage/roles/{name}',
            path: {
                'name': name,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @param name
     * @returns AmsResponseBoolean OK
     * @throws ApiError
     */
    public add(
        name: string,
    ): Observable<AmsResponseBoolean> {
        return __request(OpenAPI, this.http, {
            method: 'POST',
            url: '/api/v1/manage/roles/{name}',
            path: {
                'name': name,
            },
        });
    }
    /**
     * @param name
     * @returns AmsResponseBoolean OK
     * @throws ApiError
     */
    public delete(
        name: string,
    ): Observable<AmsResponseBoolean> {
        return __request(OpenAPI, this.http, {
            method: 'DELETE',
            url: '/api/v1/manage/roles/{name}',
            path: {
                'name': name,
            },
        });
    }
    /**
     * @param roleName
     * @param requestBody
     * @returns AmsResponseBoolean OK
     * @throws ApiError
     */
    public addPrivileges(
        roleName: string,
        requestBody: Array<string>,
    ): Observable<AmsResponseBoolean> {
        return __request(OpenAPI, this.http, {
            method: 'POST',
            url: '/api/v1/manage/roles/privileges/{roleName}',
            path: {
                'roleName': roleName,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @returns AmsResponseListString OK
     * @throws ApiError
     */
    public list(): Observable<AmsResponseListString> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/api/v1/manage/roles',
        });
    }
}
