<p-dialog header="Terms of use" [(visible)]="displayTOS">
    
Test push 4 5  ss
</p-dialog>

<div class="login-body">
    <div class="login-wrapper">
        <div class="login-panel">
            <img alt="diamond-layout" class="logo" src="./assets/layout/images/logo.svg">
            <div class="login-form">

                <br>
                <br>
                <br>
                <h2>Sign in with your account</h2>
                <p-messages [enableService]="true" redisplay = "false"></p-messages><br><br><br>
                <form [formGroup]="form">
                    <div class="grid">
                        <div class="col">
                            <div class="field">
                                <span class="p-float-label">
                                    <input id="txtEmail" class="p-inputtext-lg rounded"
                                        formControlName="email" pInputText [(ngModel)]="email"
                                        [ngClass]="displayFieldCss('email')">
                                    <label for="txtEmail">Email</label>

                                </span>
                            </div>
                        </div>
                    </div>
                    <div class="grid">
                        <div class="col">
                            <div class="field">
                                <span class="p-float-label">
                                    <input id="txtPassword" class="p-inputtext-lg rounded" type="password"
                                        formControlName="password" pInputText [ngClass]="displayFieldCss('password')"
                                        [(ngModel)]="password">
                                    <label for="txtPassword">Password</label>
                                </span>
                            </div>
                        </div>
                    </div>
                    <div class="grid">
                        <div class="col">
                            <p> <a href="#">Forgot password</a></p>
                        </div>
                    </div>


                    <button pButton pRipple type="submit" label="Sign in" class="p-button" (click)="login()"
                        [icon]="buttonDisabled?'fa-solid fa-spinner':'fa-solid fa-right-to-bracket'"></button>
                </form>

            </div><br><br>
            <p>Don't have an account? <a href="./landing/home#registrationoptions">Sign up now !</a></p>
          

        </div>
        <div class="login-image" style="background-image: url('./assets/images/AdobeStock_204895023-scaled.jpeg');"></div>
    </div>
</div>