/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import type { Observable } from 'rxjs';
import type { AmsResponseDepartmentDto } from '../models/AmsResponseDepartmentDto';
import type { AmsResponseListDepartmentDto } from '../models/AmsResponseListDepartmentDto';
import type { CreateDepartmentRequest } from '../models/CreateDepartmentRequest';
import type { UpdateDepartmentRequest } from '../models/UpdateDepartmentRequest';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
@Injectable({
    providedIn: 'root',
})
export class DepartmentControllerService {
    constructor(public readonly http: HttpClient) {}
    /**
     * @returns AmsResponseListDepartmentDto OK
     * @throws ApiError
     */
    public list2(): Observable<AmsResponseListDepartmentDto> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/api/v1/manage/departments',
        });
    }
    /**
     * @param requestBody
     * @returns AmsResponseDepartmentDto OK
     * @throws ApiError
     */
    public updateDepartment(
        requestBody: UpdateDepartmentRequest,
    ): Observable<AmsResponseDepartmentDto> {
        return __request(OpenAPI, this.http, {
            method: 'PUT',
            url: '/api/v1/manage/departments',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @param requestBody
     * @returns AmsResponseDepartmentDto OK
     * @throws ApiError
     */
    public createDepartment(
        requestBody: CreateDepartmentRequest,
    ): Observable<AmsResponseDepartmentDto> {
        return __request(OpenAPI, this.http, {
            method: 'POST',
            url: '/api/v1/manage/departments',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @param id
     * @returns AmsResponseDepartmentDto OK
     * @throws ApiError
     */
    public getDepartment(
        id: number,
    ): Observable<AmsResponseDepartmentDto> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/api/v1/manage/departments/{id}',
            path: {
                'id': id,
            },
        });
    }
}
