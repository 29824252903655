import { Component, OnInit } from "@angular/core";
import { NavigationEnd, Router } from "@angular/router";
import { MenuItem } from "primeng/api/menuitem";
import { filter } from "rxjs";
import { AuthHelper } from "src/app/helpers/auth.helper";

@Component({
  selector: "app-menu",
  templateUrl: "./menu.component.html",
  styleUrls: ["./menu.component.scss"],
})
export class MenuComponent implements OnInit {

  items: MenuItem[] = [];
  selectedRoute: string | null = null;
  constructor(private authHelper: AuthHelper, private router: Router) {
    this.router.events
      .pipe(
        filter(
          (event): event is NavigationEnd => event instanceof NavigationEnd
        )
      )
      .subscribe((event: NavigationEnd) => {
        this.selectedRoute = event.url.substring(1);
      });
  }

  ngOnInit(): void {
    this.items = [
      {
        items: [
          {
            label: "Dashboard",
            icon: "../../../assets/layout/images/dashboardIcon.svg",
            url: "admin/dashboard",
          },
          {
            label: "Video Intelligence",
            icon: "../../../assets/layout/images/videoIcon.svg",
            url: "admin/video-intelligence",
          },
        ],
      },
    ];
  }

  navigateToAddVideo(): void {
    this.router.navigate(["admin/add-video"]);
  }
}
