/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import type { Observable } from 'rxjs';
import type { AmsResponseBoolean } from '../models/AmsResponseBoolean';
import type { AmsResponseListUser } from '../models/AmsResponseListUser';
import type { AmsResponseString } from '../models/AmsResponseString';
import type { AmsResponseUser } from '../models/AmsResponseUser';
import type { UserDto } from '../models/UserDto';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
@Injectable({
    providedIn: 'root',
})
export class ManageUserControllerService {
    constructor(public readonly http: HttpClient) {}
    /**
     * @param username
     * @returns AmsResponseUser OK
     * @throws ApiError
     */
    public getUser(
        username: string,
    ): Observable<AmsResponseUser> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/api/v1/manage/users/{username}',
            path: {
                'username': username,
            },
        });
    }
    /**
     * @param username
     * @returns AmsResponseString OK
     * @throws ApiError
     */
    public resetPassword(
        username: string,
    ): Observable<AmsResponseString> {
        return __request(OpenAPI, this.http, {
            method: 'POST',
            url: '/api/v1/manage/users/{username}',
            path: {
                'username': username,
            },
        });
    }
    /**
     * @param username
     * @returns AmsResponseBoolean OK
     * @throws ApiError
     */
    public deleteUser1(
        username: string,
    ): Observable<AmsResponseBoolean> {
        return __request(OpenAPI, this.http, {
            method: 'DELETE',
            url: '/api/v1/manage/users/{username}',
            path: {
                'username': username,
            },
        });
    }
    /**
     * @param username
     * @param roleName
     * @returns AmsResponseBoolean OK
     * @throws ApiError
     */
    public addRole(
        username: string,
        roleName: string,
    ): Observable<AmsResponseBoolean> {
        return __request(OpenAPI, this.http, {
            method: 'POST',
            url: '/api/v1/manage/users/{username}/{roleName}',
            path: {
                'username': username,
                'roleName': roleName,
            },
        });
    }
    /**
     * @param username
     * @param roleName
     * @returns AmsResponseBoolean OK
     * @throws ApiError
     */
    public removeRole(
        username: string,
        roleName: string,
    ): Observable<AmsResponseBoolean> {
        return __request(OpenAPI, this.http, {
            method: 'DELETE',
            url: '/api/v1/manage/users/{username}/{roleName}',
            path: {
                'username': username,
                'roleName': roleName,
            },
        });
    }
    /**
     * @param requestBody
     * @returns AmsResponseBoolean OK
     * @throws ApiError
     */
    public signUp(
        requestBody: UserDto,
    ): Observable<AmsResponseBoolean> {
        return __request(OpenAPI, this.http, {
            method: 'POST',
            url: '/api/v1/manage/users/sign-up',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @returns AmsResponseListUser OK
     * @throws ApiError
     */
    public getUserList(): Observable<AmsResponseListUser> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/api/v1/manage/users',
        });
    }
}
