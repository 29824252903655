import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  ViewChild,
} from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { BreadcrumbService } from "src/app/helpers/breadcrumb.service";
import { VideoControllerService } from "src/app/oapi_client/data_symphony";

interface VideoInfo {
  fileName: string;
  author?: string;
  title?: string;
  duration?: string;
}

@Component({
  selector: "app-video",
  templateUrl: "./video.component.html",
  styleUrls: ["./video.component.scss"],
})
export class VideoComponent {
  @ViewChild("videoPlayer")
  videoPlayerRef!: ElementRef<HTMLVideoElement>;

  jobId: string = "";
  uniqueId: string = "";
  title: string = "";
  author: string = "";
  generatedTranscriptList: any[] = [];
  textArray: string[] = [];
  isStream: boolean = false;
  createdAt: string = "";
  videoStarted: boolean = false;
  videoPaused: boolean = false;
  isExpanded: boolean = true;
  isStreamOn: boolean = true;
  videos: VideoInfo[] = [];
  currentVideoIndex: number = 0;
  videoSource: string = "";
  streamParts: string[] = [];
  constructor(
    private route: ActivatedRoute,
    private videoService: VideoControllerService,
    private router: Router,
    private changeDetectorRef: ChangeDetectorRef,
    private breadcrumbService: BreadcrumbService
  ) {}

  ngOnInit(): void {
    this.route.params.subscribe(async (params) => {
      this.jobId = params["jobId"];

      this.route.queryParams.subscribe((params) => {
        this.createdAt = params["createdAt"];
        this.isStream = params["isStream"] === "true";
      });
    });
  }

  toggleText() {
    this.isExpanded = !this.isExpanded;
  }

  ngAfterViewInit(): void {
    this.seeVideo();
  }

  async seeVideo() {
    if (this.jobId) {
      try {
        const response = await this.videoService
          .getVideoTranscript(this.jobId)
          .toPromise();
        if (response && response.body && response.body.length > 0) {
          const streamParts = response.body[0].streamParts || [];
          this.streamParts = streamParts;

          this.videos = response.body.map((video: any) => {
            this.uniqueId = video.uniqueId;
            const durationInSeconds = parseInt(video.duration) || 0;
            const minutes = Math.floor(durationInSeconds / 60);
            const seconds = durationInSeconds % 60;
            const duration = `${minutes}m ${seconds}s`;

            if (
              video.generatedTranscriptList &&
              video.generatedTranscriptList.length > 0
            ) {
              this.generatedTranscriptList = video.generatedTranscriptList;
            } else {
              this.seeText();
            }
            return {
              fileName: video.uniqueId,
              author: video.author || "",
              title: video.title || "",
              duration: duration,
            };
          });

          if (this.videos.length > 0) {
            const firstVideo = this.videos[0];
            this.title = firstVideo.title || "";
            this.author = firstVideo.author || "";
            this.breadcrumbService.setVideoTitle(this.title);

            setTimeout(() => {
              if (this.streamParts.length > 0) {
                this.fetchStreamParts(this.uniqueId, this.streamParts[0]);
              } else {
                this.playVideo(0);
              }
            }, 0);
          } else {
            console.error("No videos found in the response.");
          }
        } else {
          console.error("No video information found in the response.");
        }
      } catch (error) {
        console.error(error);
      }
      // this.changeDetectorRef.detectChanges();
    }
  }

  concatenateText(transcript: any): string {
    if (transcript && transcript.tr) {
      return transcript.tr.map((text: { text: any }) => text.text).join(" ");
    }
    return "";
  }

  fetchStreamParts(videoId: string, partName: string) {
    const cleanPartName = partName.slice(0, -4);
    this.videoSource =
      "https://ds-api-dev.linqbase.io/api/v1/video/youtube/fetchPart/" +
      videoId +
      "/" +
      cleanPartName;
  }

  playVideo(index: number) {
    if (this.videoPlayerRef && this.videoPlayerRef.nativeElement) {
      const videoPlayer = this.videoPlayerRef.nativeElement;
      const video = this.videos[index];
      if (video) {
        this.currentVideoIndex = index;
        this.videoSource =
          "https://ds-api-dev.linqbase.io/api/v1/video/youtube/fetch/" +
          video.fileName;
        videoPlayer.onloadedmetadata = () => {
          videoPlayer.play();
        };
        videoPlayer.load();
      }
    }
  }

  selectVideo(index: number) {
    this.playVideo(index);
  }

  getCurrentVideoSource(): string {
    if (this.videos.length > 0) {
      const currentVideo = this.videos[this.currentVideoIndex];
      return currentVideo
        ? `https://ds-api-dev.linqbase.io/api/v1/video/youtube/fetch/${currentVideo.fileName}`
        : "";
    } else {
      return "";
    }
  }

  seeText() {
    if (this.jobId) {
      this.videoService.getAllSpeech2Text(this.jobId).subscribe(
        (response) => {
          if (response && response.body && response.body.length > 0) {
            this.textArray = response.body
              .map((item) => item.text)
              .filter((text) => typeof text === "string") as string[];
          } else {
            console.error("Response body is empty or invalid.");
          }
        },
        (error) => {
          console.error(error);
        }
      );
    }
  }

  togglePlayPause() {
    const videoPlayer = this.videoPlayerRef.nativeElement;
    if (videoPlayer.paused || videoPlayer.ended) {
      videoPlayer.play();
      this.videoStarted = true;
    } else {
      videoPlayer.pause();
      this.videoStarted = false;
    }
  }

  startVideo() {
    const videoPlayer = this.videoPlayerRef.nativeElement;
    videoPlayer.play();
    this.videoStarted = true;
  }
}
