<div class="title">
  <h1>{{ title }}</h1>
  <div *ngIf="isStream">
    <p>Live stream</p>
  </div>

  <div *ngIf="!isStream">
    <p>Video</p>
  </div>

  <div *ngIf="isStream && isStreamOn">
    <img src="../../../assets/images/liveStreamOn.svg" alt="Stream On Image" />
  </div>
</div>

<div class="container-fluid">
  <div
    *ngIf="videos.length > 0"
    [ngClass]="{ 'video-info': isStreamOn && isStream }"
  >
    <div class="video-container">
      <video controls autoplay #videoPlayer [src]="videoSource"></video>
    </div>

    <div class="time-wrapper" *ngIf="isStream && isStreamOn">
      <p>Timestamp</p>
      <div *ngFor="let part of streamParts; let i = index">
        <p>
          <span
            (click)="fetchStreamParts(uniqueId, part)"
            class="video-part-link"
            >Video Part {{ i + 1 }}</span
          >
        </p>
      </div>
    </div>
  </div>
  <div *ngIf="(isStream && !isStreamOn) || !isStream">
    <button class="button" (click)="toggleText()">
      <img
        src="../../../assets/images/arrow.svg"
        alt="Arrow Image"
        class="arrow-img"
        [class.rotated]="isExpanded"
      />
    </button>
    <div class="text" *ngIf="isExpanded">
      <p>Transcript</p>
      <div *ngFor="let text of textArray">
        <p>{{ text }}</p>
      </div>
      <div *ngIf="generatedTranscriptList.length > 0">
        <div *ngFor="let transcript of generatedTranscriptList">
          <div *ngIf="transcript.tr">
            <p>
              {{ concatenateText(transcript) }}
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div *ngIf="isStream && isStreamOn" class="streamOn">
  <button class="button" (click)="toggleText()">
    <img
      src="../../../assets/images/arrow.svg"
      alt="Arrow Image"
      class="arrow-img"
      [class.rotated]="isExpanded"
    />
  </button>
  <div class="text-stream" *ngIf="isExpanded">
    <p *ngIf="createdAt">
      <span>Uploaded </span> {{ createdAt | date : "dd.MM.yyyy." }}
    </p>
    <p><span>Author </span>{{ author }}</p>
    <p><span>Description</span></p>

    <div *ngFor="let text of textArray" class="text">
      <p>{{ text }}</p>
    </div>
  </div>
</div>
