import { Component } from "@angular/core";
import { Router } from "@angular/router";
import {
  CreateDownloadYoutubeRequest,
  VideoControllerService,
} from "src/app/oapi_client/data_symphony";

@Component({
  selector: "app-video-form",
  templateUrl: "./video-form.component.html",
  styleUrls: ["./video-form.component.scss"],
})
export class VideoFormComponent {
  videoSelected: boolean = false;
  streamSelected: boolean = false;
  videoUrl: string = "";
  uploaded: boolean = false;
  currentStep: number = 1;
  uploading: boolean = false;
  error: boolean = false;
  errorMessage: string = "";
  jobId: string = "";

  constructor(
    private videoService: VideoControllerService,
    private router: Router
  ) {}

  selectVideo() {
    this.videoSelected = true;
    this.streamSelected = false;
    this.currentStep = 2;
  }

  selectStream() {
    this.videoSelected = false;
    this.streamSelected = true;
    this.currentStep = 2;
  }

  async uploadVideo() {
    this.uploading = true;
    if (this.videoSelected || this.streamSelected) {
      const isStream = this.streamSelected;

      const requestPayload: CreateDownloadYoutubeRequest = {
        videoUrl: this.videoUrl,
        isStream: isStream,
        streamDuration: 30,
        partDuration: 300,
        tasks: ["youtube_download", "speech2text"],
        resultType: CreateDownloadYoutubeRequest.resultType.JSON,
      };
      try {
        const response = await this.videoService
          .youtubeVideoDownload(requestPayload)
          .toPromise();
        this.uploaded = true;
        this.uploading = false;
        if (response && response.body) {
          const jobId = response.body.substr(
            response.body.lastIndexOf("/") + 1
          );
          this.jobId = jobId;
        } else {
          console.error("Response body is undefined");
        }
      } catch (error) {
        console.error(error);
        this.error = true;
        this.uploading = false;
        this.errorMessage = "Error occurred during upload. Please try again.";
      }
    }
  }

  async seeVideo() {
    this.router.navigate(["/admin/video-intelligence"]);
  }

  goToStep(step: number) {
    if (step === 1) {
      this.currentStep = 1;
      this.streamSelected = false;
      this.videoSelected = false;
    } else if (step === 2 && this.uploaded) {
      this.currentStep = 2;
    }
  }

  retryUpload() {
    this.error = false;
    this.uploadVideo();
  }
}
