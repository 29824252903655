import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Inject, Injectable, Injector } from '@angular/core';
import { AuthHelper } from './auth.helper';

@Injectable()
export class JwtInterceptor implements HttpInterceptor {
    constructor(private injector: Injector, private http: HttpClient, @Inject('BASE_API_URL') private baseUrl: string) { }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        let authHelper = this.injector.get(AuthHelper);
        let baseUrl = this.injector.get(AuthHelper);
        let currentUser = authHelper.currentUserValue;
        if (currentUser && currentUser.token) {
            //AuthHeader can be problem in not header required requests.
            if (request.headers.get('Authorization') == null && !(request.url.indexOf('/sign-in') > 0 || request.url.indexOf('/refresh') > 0)) {
                request = request.clone({
                    setHeaders: {
                        Authorization: `Bearer ${currentUser.token}`
                    }
                });
            }
        }
        // if (request.url.indexOf('/api/') > 0) {
        //     request = request.clone({ url: `${this.baseUrl}${request.url.replace('http://localhost:9081/dsapi', '')}` });
        // }

        return next.handle(request);

    }
}

