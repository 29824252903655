import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AdminHomeComponent } from './admin-home/admin-home.component';
import { AdminLayoutComponent } from './layout/admin-layout/admin-layout.component';
import { MenuComponent } from './menu/menu.component';
import { TopBarComponent } from './layout/top-bar/top-bar.component';
import { BrowserModule } from '@angular/platform-browser';
import { SharedModule } from '../shared/shared.module';
import { AdminRoutingModule } from './admin-routing.module';
import { VideoIntelligenceComponent } from './video-intelligence/video-intelligence.component';
import { TieredMenuModule } from 'primeng/tieredmenu';
import { BreadcrumbModule } from 'primeng/breadcrumb';
import { BreadcrumbComponent } from '../shared/breadcrumb/breadcrumb.component';
import { VideoFormComponent } from './video-form/video-form.component';
import { VideoComponent } from './video/video.component';
import { ModalComponent } from '../../app/shared/modal/modal.component';

@NgModule({
  declarations: [
    AdminHomeComponent,
    AdminLayoutComponent,
    MenuComponent,
    TopBarComponent,
    VideoIntelligenceComponent,
    BreadcrumbComponent,
    VideoFormComponent,
    VideoComponent,
    ModalComponent,
  ],
  imports: [
    CommonModule,
    BrowserModule,
    AdminRoutingModule,
    SharedModule,
    TieredMenuModule,
    BreadcrumbModule,
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class AdminModule {}
