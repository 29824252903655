/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export type CreateLinkedinProfileFilterRequest = {
    personNameSurname?: string;
    personAbout?: string;
    personTitle?: string;
    personLocation?: string;
    experienceInstitutionName?: string;
    experienceTitle?: string;
    experienceAbout?: string;
    experienceLocation?: string;
    educationDegree?: string;
    educationInstitutionName?: string;
    certificationTitle?: string;
    certificationInstitutionName?: string;
    certificationAbout?: string;
    publicationTitle?: string;
    publicationInstitutionName?: string;
    publicationAbout?: string;
    courseTitle?: string;
    courseAbout?: string;
    projectTitle?: string;
    projectAbout?: string;
    awardTitle?: string;
    awardInstitution?: string;
    languageName?: string;
    languageDegree?: string;
    testScoreTitle?: string;
    testScoreEquals?: string;
    jobId?: string;
    pageSize?: number;
    pageIndex?: number;
    sortBy?: string;
    sortDirection?: CreateLinkedinProfileFilterRequest.sortDirection;
};
export namespace CreateLinkedinProfileFilterRequest {
    export enum sortDirection {
        ASC = 'ASC',
        DESC = 'DESC',
    }
}

