/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import type { Observable } from 'rxjs';
import type { DSResponseListJobTraceWithUserInfoDto } from '../models/DSResponseListJobTraceWithUserInfoDto';
import type { DSResponseObject } from '../models/DSResponseObject';
import type { DSResponseString } from '../models/DSResponseString';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
@Injectable({
    providedIn: 'root',
})
export class JobTraceControllerService {
    constructor(public readonly http: HttpClient) {}
    /**
     * @param jobId
     * @returns DSResponseString OK
     * @throws ApiError
     */
    public searchByJobId(
        jobId: string,
    ): Observable<DSResponseString> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/api/v1/jobTrace/{jobId}',
            path: {
                'jobId': jobId,
            },
        });
    }
    /**
     * @param jobId
     * @returns DSResponseObject OK
     * @throws ApiError
     */
    public deleteJob(
        jobId: string,
    ): Observable<DSResponseObject> {
        return __request(OpenAPI, this.http, {
            method: 'DELETE',
            url: '/api/v1/jobTrace/{jobId}',
            path: {
                'jobId': jobId,
            },
        });
    }
    /**
     * @param createdBy
     * @returns DSResponseListJobTraceWithUserInfoDto OK
     * @throws ApiError
     */
    public jobProfileStatistics(
        createdBy: number,
    ): Observable<DSResponseListJobTraceWithUserInfoDto> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/api/v1/jobTrace/statistics/linkedin/profile/{createdBy}',
            path: {
                'createdBy': createdBy,
            },
        });
    }
    /**
     * @param createdBy
     * @returns DSResponseListJobTraceWithUserInfoDto OK
     * @throws ApiError
     */
    public jobPostStatistics(
        createdBy: number,
    ): Observable<DSResponseListJobTraceWithUserInfoDto> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/api/v1/jobTrace/statistics/linkedin/post/{createdBy}',
            path: {
                'createdBy': createdBy,
            },
        });
    }
    /**
     * @param createdBy
     * @returns DSResponseListJobTraceWithUserInfoDto OK
     * @throws ApiError
     */
    public jobYoutubeStatistics(
        createdBy: number,
    ): Observable<DSResponseListJobTraceWithUserInfoDto> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/api/v1/jobTrace/statistics/download/youtube/{createdBy}',
            path: {
                'createdBy': createdBy,
            },
        });
    }
    /**
     * @param createdBy
     * @returns DSResponseListJobTraceWithUserInfoDto OK
     * @throws ApiError
     */
    public jobLinkedinList(
        createdBy: number,
    ): Observable<DSResponseListJobTraceWithUserInfoDto> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/api/v1/jobTrace/list/linkedin/profile/{createdBy}',
            path: {
                'createdBy': createdBy,
            },
        });
    }
    /**
     * @param createdBy
     * @returns DSResponseListJobTraceWithUserInfoDto OK
     * @throws ApiError
     */
    public jobPostList(
        createdBy: number,
    ): Observable<DSResponseListJobTraceWithUserInfoDto> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/api/v1/jobTrace/list/linkedin/post/{createdBy}',
            path: {
                'createdBy': createdBy,
            },
        });
    }
    /**
     * @param createdBy
     * @returns DSResponseListJobTraceWithUserInfoDto OK
     * @throws ApiError
     */
    public jobYoutubeList(
        createdBy: number,
    ): Observable<DSResponseListJobTraceWithUserInfoDto> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/api/v1/jobTrace/list/download/youtube/{createdBy}',
            path: {
                'createdBy': createdBy,
            },
        });
    }
}
