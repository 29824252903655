<div class="title">
  <h1>Dashboard</h1>
</div>

<div class="layout-dashboard">
  <div class="grid">
    <div class="col-12 md:col-6 xl:col-6">
      <div class="card dashboard-wrapper no-gutter widget-overview-box">
        <div class="card-dashboard">
          <div>
            <img
              src="../../../assets/images/videoFolderIcon.svg"
              alt="Video Folder image"
            />
          </div>
          <div class="text-container">
            <span>Videos uploaded</span>
            <p>5</p>
            <span>? added today</span>
          </div>
        </div>

        <div class="card-dashboard">
          <div>
            <img
              src="../../../assets/images/streamDashboardIcon.svg"
              alt="Stream Dashboard image"
            />
          </div>
          <div class="text-container">
            <span>Streams uploaded</span>
            <p>3</p>
          </div>
        </div>
        <!-- <span class="overview-icon"> <i class="fa-solid fa-search"></i> </span
        ><span class="overview-title">Talent Hunts</span>
        <div class="grid overview-detail">
          <div class="col-6">
            <div class="overview-number">
              {{ this.statsCounts.inProgress + this.statsCounts.started }}
            </div>
            <div class="overview-subtext">In progress</div>
          </div>
          <div class="col-6">
            <div class="overview-number">{{ this.statsCounts.completed }}</div>
            <div class="overview-subtext">Completed</div>
          </div> -->
      </div>
    </div>
  </div>
</div>
