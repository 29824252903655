import { DatePipe } from '@angular/common';
import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { MessageService } from 'primeng/api';
import { Table } from 'primeng/table';
import { interval, Subject, takeUntil } from 'rxjs';
import { AuthHelper } from 'src/app/helpers/auth.helper';
import { DataService } from 'src/app/helpers/data.service';
import { PaginationService } from 'src/app/helpers/pagination.service';
import { JobTraceControllerService, JobTraceWithUserInfoDto } from 'src/app/oapi_client/data_symphony';
import { ModalComponent } from 'src/app/shared/modal/modal.component';

@Component({
  selector: 'app-video-intelligence',
  templateUrl: './video-intelligence.component.html',
  styleUrls: ['./video-intelligence.component.scss'],
  providers: [DatePipe],
})
export class VideoIntelligenceComponent implements OnInit {
  currentUserID: number = 0;
  private refreshTimer$ = interval(60000);
  private destroy$ = new Subject<void>();
  searches$: JobTraceWithUserInfoDto[] = [];
  isStreamSelected: boolean = false;
  confirmationModalVisible: boolean = false;

  @ViewChild('dt') dataTable: Table | undefined;
  @ViewChild("confirmationModal") confirmationModal: ModalComponent | undefined;
  constructor(
    private authHelper: AuthHelper,
    private dataService: DataService,
    private router: Router,
    protected paginationService: PaginationService,
    private jobTraceService: JobTraceControllerService,
    private messageService: MessageService,
  ) {}

  ngOnInit() {
    this.authHelper.currentUser.subscribe(
      (user) => (this.currentUserID = user.id)
    );
    if (this.dataTable) {
      this.dataTable.first =
        this.paginationService.searchListPaginationState.first;
      this.dataTable.rows =
        this.paginationService.searchListPaginationState.rows;
    }

    this.dataService.updateSearches(this.currentUserID);
    this.dataService.searches$.subscribe(
      (searches: JobTraceWithUserInfoDto[]) => {
        this.searches$ = searches;
      }
    );
    this.refreshTimer$.pipe(takeUntil(this.destroy$)).subscribe(() => {
      this.dataService.updateSearches(this.currentUserID);
      this.dataService.searches$.subscribe(
        (searches: JobTraceWithUserInfoDto[]) => {
          this.searches$ = searches;
        }
      );
    });
  }

  formatDuration(duration: any): string {
    if (duration) {
      const hours = Math.floor(duration / 3600);
      const minutes = Math.floor((duration % 3600) / 60);
      const seconds = duration % 60;

      if (hours > 0) {
        return `${hours}:${minutes}:${seconds}`;
      } else {
        return `${minutes}:${seconds}`;
      }
    } else {
      return '';
    }
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  navigateToAddVideo() {
    this.router.navigate(['/admin/add-video']);
  }

  async seeVideo(jobId: string, isStream: boolean, createdAt: string) {
    if (jobId) {
      try {
        await new Promise((resolve) => setTimeout(resolve, 2000));
        this.router.navigate(['/admin/video', jobId], {
          queryParams: { isStream, createdAt },
        });
      } catch (error) {
        console.error(error);
      }
    }
  }

  deleteSearch(search: any) {
    if (this.confirmationModal) {
      this.confirmationModal!.isVisible = true;
      this.confirmationModal.header =
        'Are you sure you want to delete this record?';
      this.confirmationModal.message = "Once you confirm you can't go back.";
      this.confirmationModal.acceptText = 'Yes';
      this.confirmationModal.cancelText = 'No';
      this.confirmationModal.isVisible = true;

      this.confirmationModal.accept.subscribe(() => {
        this.jobTraceService.deleteJob(search.jobId).subscribe((result) => {
          this.dataService.updateSearches(this.currentUserID);
          this.messageService.add({
            severity: 'success',
            summary: 'Success',
            detail: 'Deleted Successfully',
            life: 3000,
          });
        });
        this.confirmationModal!.isVisible = false;
      });

      this.confirmationModal.cancel.subscribe(() => {
        this.confirmationModal!.isVisible = false;
      });
    }
  }
}
