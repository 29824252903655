<div class="layout-sidebar">
  <div class="layout-sidebar-top">
    <a class="logo" href="#/"
      ><img
        id="app-logo"
        src="./assets/layout/images/dummyLogo.svg"
        alt="Dummy logo"
        class="logo-image"
    /></a>
    <img src="./assets/layout/images/menuIcon.svg" alt="Menu Icon" />
  </div>
  <div class="layout-menu-container">
    <ul class="layout-menu">
      <li class="layout-root-menuitem" *ngFor="let root of items">
        <div class="layout-menuitem-root-text">{{ root.label }}</div>
        <ul role="menu" *ngIf="root.items">
          <li *ngFor="let item of root.items">
            <div [class.active-menuitem]="selectedRoute === item.url">
              <a
                routerLinkActive="active-route"
                pRipple
                class="active-route"
                tabindex="0"
                [href]="item.url"
              >
                <img [src]="item.icon" alt="{{ item.label }}" />
                <span class="item-label">{{ item.label }}</span>
              </a>
            </div>
          </li>
        </ul>
      </li>
    </ul>
    <button class="menu-add-button" (click)="navigateToAddVideo()">+ Add new video</button>
  </div>
</div>
