import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class BreadcrumbService {
  private videoTitleSubject: BehaviorSubject<string> =
    new BehaviorSubject<string>("");

  setVideoTitle(title: string) {
    this.videoTitleSubject.next(title);
  }

  getVideoTitle() {
    return this.videoTitleSubject.asObservable();
  }
}
