import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { authGuard } from "../helpers/auth-guard.service";
import { AdminHomeComponent } from "./admin-home/admin-home.component";
import { AdminLayoutComponent } from "./layout/admin-layout/admin-layout.component";
import { VideoFormComponent } from "./video-form/video-form.component";
import { VideoIntelligenceComponent } from "./video-intelligence/video-intelligence.component";
import { VideoComponent } from "./video/video.component";

const routes: Routes = [
  {
    path: "",
    component: AdminLayoutComponent,
    children: [
      {
        path: "admin/dashboard",
        component: AdminHomeComponent,
        canActivate: [authGuard],
      },
      {
        path: "admin/video-intelligence",
        component: VideoIntelligenceComponent,
        canActivate: [authGuard],
      },
      {
        path: "admin/add-video",
        component: VideoFormComponent,
        canActivate: [authGuard],
      },
      {
        path: "admin/video/:jobId",
        component: VideoComponent,
        canActivate: [authGuard],
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class AdminRoutingModule {}
