import { BehaviorSubject, Observable } from "rxjs";
import { Injectable, InjectionToken, Optional } from "@angular/core";
import { Router } from "@angular/router";
import { MessageService } from "primeng/api";
import { Observer } from "rxjs/internal/types";
import { User } from "./User";
export const API_BASE_URL = new InjectionToken<string>("API_BASE_URL");
import jwt_decode from "jwt-decode";
import * as Sentry from "@sentry/browser";
import { AuthenticationControllerService } from "../oapi_client/data_symphony/services/AuthenticationControllerService";
import { TokenRefreshRequest } from "../oapi_client/data_symphony/models/TokenRefreshRequest";
import { AuthRequest } from "../oapi_client/data_symphony/models/AuthRequest";

@Injectable()
export class AuthHelper {
  private currentUserSubject: BehaviorSubject<User>;
  private tokenRefreshing = false;
  public currentUser: Observable<User>;
  userData: User = Object.create(null);
  TOKEN_KEY = "Token";
  REFRESH_TOKEN_KEY = "RefreshToken";
  constructor(
    private router: Router,
    private messageService: MessageService,
    private readonly authenticationControllerService: AuthenticationControllerService
  ) {
    this.currentUserSubject = new BehaviorSubject<User>(
      JSON.parse(localStorage.getItem("currentUser")!)
    );
    this.currentUser = this.currentUserSubject.asObservable();
  }

  public get currentUserValue(): User {
    return this.currentUserSubject.value;
  }

  public getNewAccessToken(refreshToken: string): Observable<boolean> {
    var refresh = new Observable<boolean>((observer: Observer<boolean>) => {
      this.authenticationControllerService
        .refresh(<TokenRefreshRequest>{ refreshToken: refreshToken })
        .subscribe(
          (response) => {
            if (response.success) {
              this.userData.token = response!.body!.accessToken!;
              this.currentUserSubject.subscribe((user) => {
                (this.userData.id = user.id),
                  (this.userData.name = user.name),
                  (this.userData.userName = user.userName);
              });
              localStorage.setItem(
                this.TOKEN_KEY,
                response!.body!.accessToken!
              );
              localStorage.setItem(
                "currentUser",
                JSON.stringify(this.userData)
              );
              localStorage.setItem(
                this.REFRESH_TOKEN_KEY,
                response!.body!.refreshToken!
              );
              this.currentUserSubject.next(this.userData);
              observer.next(true);
            } else {
              this.messageService.add({
                severity: "error",
                summary: "Error",
                detail: "Invalid username or password",
              });
              Sentry.captureException(
                new Error("Invalid username or password"),
                { tags: { customTag: "AuthHelper" }, level: "info" }
              );
              observer.next(false);
            }
          },
          (error) => {
            this.messageService.add({
              severity: "error",
              summary: "Error",
              detail: "Token Expired. You must relogin.",
            });
            this.logout();
          }
        );
    });
    return refresh;
  }
  public login(email: string, password: string): Observable<boolean> {
    return new Observable<boolean>((observer: Observer<boolean>) => {
      this.authenticationControllerService
        .signIn(<AuthRequest>{ email: email, password: password })
        .subscribe(
          (response) => {
            if (response.success) {
              this.userData.userName = email;

              this.userData.token = response!.body!.token!;
              this.userData.id = response!.body!.id!;
              this.userData.name = email;

              localStorage.setItem(this.TOKEN_KEY, response!.body!.token!);
              localStorage.setItem(
                "currentUser",
                JSON.stringify(this.userData)
              );
              localStorage.setItem(
                this.REFRESH_TOKEN_KEY,
                response!.body!.refreshToken!
              );
              this.currentUserSubject.next(this.userData);
              this.router.navigateByUrl("/admin/dashboard");
              observer.next(true);
            } else {
              this.messageService.add({
                severity: "error",
                summary: "Error",
                detail: "Invalid username or password",
              });
              Sentry.captureMessage("Invalid username or password", {
                tags: { customTag: "AuthHelper" },
                level: "info",
              });
              observer.next(false);
            }
          },
          (error) => {
            this.messageService.add({
              severity: "error",
              summary: "Error",
              detail: "An error occured during sign-in",
            });
            Sentry.captureException(
              new Error("An error occured during sign-in"),
              { tags: { customTag: "AuthHelper" }, level: "error" }
            );
          }
        );
    });
  }
  logout() {
    // remove user from session storage to log user out
    localStorage.removeItem("currentUser");
    localStorage.removeItem(this.TOKEN_KEY);
    localStorage.removeItem(this.REFRESH_TOKEN_KEY);
    this.currentUserSubject.next(Object.create(null));
    this.router.navigateByUrl("/landing/login");
  }
  public loginUsingToken(token: string, username: string): void {
    this.userData.userName = username;
    this.userData.token = token;
    localStorage.setItem(this.TOKEN_KEY, token);
    localStorage.setItem("currentUser", JSON.stringify(this.userData));
    localStorage.setItem(this.REFRESH_TOKEN_KEY, this.TOKEN_KEY);
    this.currentUserSubject.next(this.userData);
  }

  get Token(): string | null {
    return localStorage.getItem(this.TOKEN_KEY);
  }
  get RefreshToken(): string | null {
    return localStorage.getItem(this.REFRESH_TOKEN_KEY);
  }
  setToken(token: string) {
    localStorage.setItem(this.TOKEN_KEY, token);
  }
  public isAuthenticated(): boolean {
    try {
      const token = localStorage.getItem(this.TOKEN_KEY);
      const base64Url = token?.split(".")[1];
      const base64 = base64Url?.replace("-", "+").replace("_", "/") || "";
      const payload = JSON.parse(atob(base64));
      const expirationTimestamp = payload.exp;
      const currentTimestamp = Math.floor(Date.now() / 1000);

      if (token == null) {
        return false;
      } else if (currentTimestamp > expirationTimestamp) {
        //If the token is expired
        this.getNewAccessToken(this.RefreshToken || "").subscribe(
          (res) => {
            if (res) return true;
            else return false;
          },
          (error) => {
            // Handle any errors that occur during the refresh process
            Sentry.captureException("System can not refresh the token.", {
              tags: { customTag: "AuthHelper / RefreshTokenError" },
              level: "error",
            });
            return false;
          }
        );
        return true;
      } else {
        return true;
      }
    } catch {
      return false;
    }
  }
  private getDataFromToken(token: any) {
    let data = {};
    if (token !== null) {
      data = jwt_decode(token);
    }
    return data;
  }
  public hasPermission(action: Actions) {
    var data: any = this.getDataFromToken(this.Token);
    var role: string[] =
      data["http://schemas.microsoft.com/ws/2008/06/identity/claims/role"];
    if (typeof role == "string") {
      return role == action;
    } else {
      let f = role.find((r) => r == action);
      return f != undefined;
    }
  }
}

//This Error codes are used before?
enum ErrorCodes {
  NoProfileRegisteredWarningCode = "WRN01",
  InvalidUserNameOrPasswordCode = "ERR01",
  AccountAlreadyConfirmedCode = "ERR02",
  AccountMustBeConfirmedCode = "ERR03",
  AccountAlreadyBeConfirmedCode = "ERR04",
}
export enum Actions {
  Dashboard_GetStatistics = "Dashboard.GetStatistics",
}
