<div
  class="layout-wrapper layout-sidebar-light layout-static"
  data-theme="light"
>
  <div class="layout-content-wrapper">
    <app-top-bar></app-top-bar>
    <div class="layout-content">
      <!-- <p-messages [enableService]="true"></p-messages> <p-toast position="top-left"></p-toast> -->
      <router-outlet></router-outlet>
    </div>
  </div>
</div>
<!-- <p-confirmDialog #cd [style]="{ width: '455px' }">
  <ng-template pTemplate="footer">
    <button
      type="button"
      pButton
      icon="pi pi-times"
      label="No"
      (click)="cd.reject()"
      style="background-color: gray; color: white"
    ></button>
    <button
      type="button"
      pButton
      icon="pi pi-check"
      label="Yes"
      (click)="cd.accept()"
    ></button>
  </ng-template>
</p-confirmDialog> -->
